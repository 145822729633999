<template>
  <div class="sshx">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>智慧阅读系统</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />我们的优势
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />智慧阅读系统
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img
                      src="../../assets/resource/sshx.png"
                      alt=""
                    /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img
                        src="../../assets/icons/separater.png"
                        alt=""
                      /></span>
                  </div>
                  <h2>智慧阅读系统</h2>
                </div>
                <div class="text">
                  <p>平台依托数字技术，通过汇聚优质资源、营造互动场景、展示阅读成果，为不同学段学生、社会公众提供丰富多彩的读书空间。</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col
            :xl="8" :lg="8" :md="12" :sm="24"
            v-for="(item,index) in feature"
            :key="index"
          >
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{item.title}}</h3>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
          </Col>
        </Row>

      </div>
    </div>
  </div>
</template>
<script>
import zhyd from "./zhyd.js";
export default zhyd;
</script>

<style lang="less" scoped>
@import "./zhyd.less";
</style>